@tailwind base;
@tailwind components;
@tailwind utilities;
@layer utilities {

  .clip-your {

    clip-path: polygon(3% 7%, 98% 5%, 98% 24%, 94% 26%, 94% 75%, 98% 75%, 98% 93%, 3% 93%);

  }

}
/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */
